<template>
    <div class="sp-login-wrapper">
        <div>
            <img alt="Mirantis Logo" src="@/assets/images/mirantis-logo.svg">
        </div>
        <div class="sp-login-form">
            <h1>Mirantis Security Portal</h1>
            <span>Please log in to access data</span>
            <template v-if="hasMethod('ModelBackend')">
                <span v-show="authError !== null" class="sp-login-errormessage">
                    <h4>Error:</h4>
                    {{ authError }}
                </span>
                <form @submit.prevent="login">
                    <span aria-hidden="true" class="material-symbols-outlined">person</span>
                    <input v-model="username" type="text" autocomplete="username" placeholder="Username">
                    <span class="material-symbols-outlined">lock_open</span>
                    <input v-model="password" type="password" autocomplete="current-password" placeholder="Password">
                    <input type="submit" value="Log In">
                </form>
                <div class="sp-login-separator">
                    <hr><span>OR</span>
                </div>
            </template>
            <div class="sp-provider-list">
                <template v-if="hasMethod('GoogleOAuth2')">
                    <button @click="loginSocial('google-oauth2')">
                        <img src="@/assets/images/google-icon.svg" alt="google">
                        <span>Continue as Mirantis employee</span>
                    </button>
                </template>
                <template v-if="hasMethod('SalesforceOAuth2')">
                    <button @click="loginSocial('salesforce-oauth2')">
                        <img src="@/assets/images/salesforce-icon.svg" alt="salesforce">
                        <span>Continue as Mirantis customer</span>
                    </button>
                </template>
                <template v-if="hasMethod('SalesforceOAuth2Sandbox')">
                    <button @click="loginSocial('salesforce-oauth2-sandbox')">
                        <img src="@/assets/images/salesforce-icon.svg" alt="salesforce">
                        <span>SalesforceOAuth2Sandbox</span>
                    </button>
                </template>
                <template v-if="hasMethod('SalesForceCommunityOAuth2')">
                    <button @click="loginSocial('auth0')">
                        <img src="@/assets/images/salesforce-icon.svg" alt="salesforce">
                        <span>Continue as Mirantis customer</span>
                    </button>
                </template>
            </div>
        </div>
        <div class="sp-login-footer">© 2005 - 2022 Mirantis. All rights reserved.</div>
    </div>
</template>

<script>
    export default {
        name: 'LoginView',
        data() { return {
            username: '',
            password: '',
        }},
        computed: {
            authError() { return this.$store.state.authError },
        },
        components: {},
        methods: {
            hasMethod(method) { return this.$store.state.authMethods.includes(method) },
            login() {
                this.$store.dispatch('login', {
                    username: this.username,
                    password: this.password
                })
                this.username = ''
                this.password = ''
            },
            loginSocial(provider) {
                window.location.href = '/auth/login/' + provider;
            }
        },
        created() {
            this.$store.dispatch('authMethods')
        }
    }
</script>

<style scoped>
.sp-login-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: url('@/assets/images/light-blue-gradient.jpg') no-repeat;
    background-size: cover;
    letter-spacing: 0px;
    line-height: 1.44;
}
.sp-login-form {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px;
    /* add media */
    width: 320px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #66707A;
    font-family: Montserrat,sans-serif;
    font-size: 14px;
}
.sp-login-form>form {
    width: 100%;
    display: block;
}
.sp-login-form>h1 {
    font-size: 21px;
}
.sp-login-form>span {
    margin-bottom: 15px;
}
.sp-login-errormessage {
    color:#cc0000;
    font-size: 14px;
    align-self: flex-start;
}
input {
    margin-top: 8px;
    width: 100%;
    height: 40px;
    display: block;
    border: 1px solid #CCCFD3;
    border-radius: 2px;
    background-color: #fff;
    box-sizing: border-box;
    -webkit-appearance: none;
    font-size: inherit;
    transition: all 0.1s;
}
input[type=text], input[type=password] {
    font-family: Montserrat,sans-serif;
    padding-left: 40px;
}
input[type=text]:hover, input[type=password]:hover {
    border: 1px solid #e02d3a;
}
input[type=text]::placeholder, input[type=password]::placeholder {
    opacity: 0.8;
}
input[type=submit]:hover {
    color: #e02d3a;
    background-color: #fff;
    border: 2px solid #ef2369;
    cursor: pointer;
}
input[type=submit] {
    background: #EF2369 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    height: 28px;
    padding: 0 20px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    color: #FFFFFF;
}
.sp-login-separator {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;
    align-items: center;
    opacity: 0.7;
    background-color: inherit;
    opacity: 0.5;
}
.sp-login-separator>hr {
    width: 100%;
}
.sp-login-separator>span {
    background-color: inherit;
    padding: 0 5px 0 5px;
    transform: translate(0,-1.4em);
    text-transform: uppercase;
    font-size: 0.9em;
}
.sp-provider-list {
    display: grid;
    width: 100%;
    gap: 10px;
}
.sp-provider-list>button {
    background: inherit;
    border: 1px solid #CCCFD3;;
    border-radius: 3px;
    align-items: center;
    display: flex;
    color: inherit;
    font-size: 14px;
    font-family: inherit;
    height: 48px;
    padding-left: 14px;
}
.sp-provider-list>button:hover {
    border-color: #ef2369;
    cursor: pointer;
}
.sp-provider-list>button>img {
    width: 24px;
}
.sp-provider-list>button>span {
    margin-left: 14px;
}
.sp-login-footer {
    font-size: 11px;
    color: #66707A;
}
.material-symbols-outlined {
    font-size: 1.6em;
    position: absolute;
    margin-top: 16px;
    margin-left: 10px;
    color: #ccc;
    font-variation-settings: 'FILL' 1;
}
</style>
