<template>
<div class="sp-sidebar-wrapper">
    <div class="sp-sidebar">
        <div class="sp-sidebar-menu">
            <div class="sp-sidebar-menu-item">
                <router-link to="/cve">
                    <span class="material-symbols-outlined">security</span>
                    <span class="sp-sidebar-label">Vulnerabilities</span>
                </router-link>
            </div>
            <div class="sp-sidebar-menu-item">
                <router-link to="/statistics">
                    <span class="material-symbols-outlined">bar_chart</span>
                    <span class="sp-sidebar-label">Statistics</span>
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'SideBar',
}
</script>

<style scoped lang="less">
.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 300,
        'GRAD' 0,
        'opsz' 48;
    font-size: 20px;
}
.sp-sidebar-label{
    white-space: nowrap;
    padding-top: 2px;
}
.sp-sidebar-wrapper {
    position: absolute;
    background-color: #222d32;
    top: 0;
    left: 0;
    padding-top: 60px;
    min-height: 100%;
    width: 50px;
    z-index: 10;
    /*-webkit-transition: -webkit-transform .2s ease-in-out,width .2s ease-in-out;*/
    -moz-transition: -moz-transform .2s ease-in-out,width .2s ease-in-out;
    -o-transition: -o-transform .2s ease-in-out,width .2s ease-in-out;
    transition: transform .2s ease-in-out,width .2s ease-in-out;

    &:hover {
        width: 170px;
    }
}
.sp-sidebar {
    padding-bottom: 10px;
    a {
        color: #b8c7ce;
    }
}
.sp-sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
.sp-sidebar-menu, .sp-sidebar-wrapper {
    // overflow: hidden;
}
.sp-sidebar-menu li.active>a, .sp-sidebar a.router-link-active {
    border-left-color: #3c8dbc;
}
.sp-sidebar-menu-item {
    position: relative;
    margin: 0;
    padding: 0;

    &>a {
        border-left: 3px solid transparent;
        padding: 12px 5px 12px 15px;
        height: 40px;
        display: flex;
        flex-flow: row wrap;
        overflow: hidden;

        span {
            width: 30px;
            height: 40px;
        }
    }
}
.sp-sidebar-menu-item:hover>a, .sp-sidebar a.router-link-active {
    color: #fff;
    background: #1e282c;
}
a {
    // color: #337ab7;
    text-decoration: none;
    background-color: transparent;
    &:hover, :active, :focus {
        outline: none;
        text-decoration: none;
    }
}
// a:focus {
//     outline: 5px auto -webkit-focus-ring-color;
//     outline-offset: -2px;
// }
// a:focus, a:hover {
//     color: #23527c;
//     text-decoration: none;
// }

</style>
