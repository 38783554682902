<template>
<div class="sp-toggle">
    <div class="sp-toggle__control-part">
        <label tabindex="0" class="sp-toggle__control">
            <input
                type="checkbox"
                class="sp-toggle__field"
                tabindex="-1"
                :checked="darkTheme"
                @change="toggleTheme"
            >
            <div class="sp-toggle__control-marker"/>
            <label class="sp-toggle__label sp-toggle__label--light">Light theme</label>
            <label class="sp-toggle__label sp-toggle__label--dark">Dark theme</label>
            <span class="sp-toggle__control-bg"/>
        </label>
        <div class="sp-toggle__icon-placeholder"/>
    </div>
</div>
</template>

<script>
export default {
    name: 'ThemeSwitcher',
    data() { return {
        darkTheme: false,
    }},
    methods: {
        toggleTheme() {
           this.darkTheme = !this.darkTheme
           const themeName = this.darkTheme ? 'sp-theme-dark' : 'sp-theme-light'
           document.body.className = themeName
           localStorage.setItem('theme', themeName);
        },
    },
    mounted() {
        this.darkTheme = localStorage.getItem('theme') === 'sp-theme-dark'
    },
}
</script>

<style scoped lang="less">
.sp-toggle__field:checked {
    &~.sp-toggle__control-bg {
        background-color: #0073ec;
    }
    &~.sp-toggle__control-marker {
        transform: translate(16px);
    }
    &~.sp-toggle__label--light {
        display: none;
    }
    &~.sp-toggle__label--dark {
        display: block;
    }
}
.sp-toggle__label--dark {
    display: none;
}
.sp-toggle__control {
    position: relative;
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 40px;
    height: 24px;
    border-radius: 12px;
    border: 4px solid transparent;
    transition: all .3s ease 0s;
    cursor: pointer;
    margin-top: 0;
    margin-right: 50px;
    outline: none;
}
.sp-toggle__field {
    position: absolute;
    top: auto;
    left: auto;
    opacity: 0;
    width: 1px;
    height: 1px;
    display: none;
}
.sp-toggle {
    min-width: 88px;
    flex-direction: column;
}
.sp-toggle, .sp-toggle__control-part {
    height: auto;
    display: flex;
}
.sp-toggle__control-marker {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    transition: all .2s ease 0s;
    transform: translate(0);
}
.sp-toggle__label {
    position: absolute;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    top: 0;
    right: -12px;
    transform: translateX(100%);
    cursor: pointer;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-size: 12px;
    color: #fff;
    font-family: Open Sans,Roboto,sans-serif;
    letter-spacing: 0;
    line-height: unset;
    font-style: normal;
    font-weight: 400;
    max-height: 34px;
}
.sp-toggle__control-bg {
    position: absolute;
    top: -4px;
    left: -4px;
    bottom: -4px;
    right: -4px;
    border-radius: 12px;
    background-color: #404d59;
    z-index: -1;
}
.sp-toggle__icon-placeholder {
    margin-left: 4px;
    width: 24px;
    height: 24px;
}
</style>
