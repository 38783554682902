import { createApp } from 'vue'
import store from './store'
import router from './router'
import { axiosInstance } from './axiosInstance'
import App from './App.vue'


store.$axios = axiosInstance

const app = createApp(App)
    .use(router)
    .use(store)

app.config.globalProperties.$axios = axiosInstance

store.dispatch('getUserProfile')
.finally(() => app.mount('#app'))
