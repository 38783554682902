<template>
    <div class="sp-modal-card-container" :class="'sp-modal-card-container-' + blockClass">
        <div class="sp-modal-card-header">
            {{ blockHeader }}
            <div class="expand-filters">
                <span
                    title="Sort list"
                    class="material-symbols-outlined"
                    :class="{'opened-filters': showFilters}"
                    @click="toggleFilters"
                >
                    more_horiz
                </span>
            </div>
            <div class="filters-block" :class="{'visible': showFilters }">
                <input v-model="filterString" @keypress="filterItems">
                <div>
                <span title="Filter list" class="material-symbols-outlined" @click="clearFilters">
                    delete_forever
                </span>
                <span title="Filter list" class="material-symbols-outlined" @click="filterSelectedItem">
                    filter_list
                </span>
                <span title="Sort list" class="material-symbols-outlined" @click="sortItems">
                    arrow_drop_up
                </span>
                <span title="Sort list" class="material-symbols-outlined" @click="sortItemsReverse">
                    arrow_drop_down
                </span>
            </div>
            </div>
        </div>
        <div class="sp-modal-card-body" @click="hideFilters">
            <div
                v-for="item in items"
                :key="item.id"
                class="sp-modal-card-container-row"
                :class="{ 'selected-row': item.name == selectedItem }" @click="getSelectedItem(item.name)"
            >
                <span class="list_item" :title="item.clickItemDetails?.artifact_id">{{ item.name }}</span>
                <span
                    v-if="item.severity && !isCustomer"
                    class="clickable"
                    :class="severityLabelClass(item.severity)"
                    @click.stop.prevent="getSeverityChange(item.clickItemName, item.clickItemDetails)"
                >
                    {{ item.severity }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CVEDetailDataList',
    props: {

        listHeader: {
            type: String,
            default: null
        },
        listId: {
            type: String,
            default: null
        },
        listItems: {
            type: Array,
            default: null
        },
        selectedItem: {
            type: String,
            default: null
        },

    },
    emits: ['custom-event', 'custom-selection'],
    data() {
        return {
            blockHeader: this.listHeader,
            blockClass: this.listId,
            items: this.listItems,
            selected: this.selectedItem,
            filterString: '',
            showFilters: false
        }
    },
    computed: {
        isCustomer() { return this.$store.state.userProfile.customer },
    },
    methods: {
        severityLabelClass(severity_name) {
            const severity = severity_name || 'none'
            return ['sp-severity-label', `sp-severity-label-${severity.toLowerCase()}`]
        },
        getSeverityChange(cve_name, element) {
            this.$emit('custom-event',this.listId, cve_name, element)
        },
        getSelectedItem ( name ) {
            this.selected= name;
            this.$emit('custom-selection', this.listId, name)
        },

        clearFilters() {
            this.filterString = "";
            this.items = this.listItems;
        },
        filterSelectedItem() {
            this.items = this.listItems.filter(item => item.name == this.selectedItem)
        },
        filterItems() {
            this.items = this.listItems.filter(item => item.name.toLowerCase().trim().includes(this.filterString.toLowerCase().trim()))
        },
        sortItems() {
            this.filterItems()
            this.items.sort((a, b) => a.name.localeCompare(b.name))
        },
        sortItemsReverse() {
            this.filterItems()
            this.items.sort((a, b) => b.name.localeCompare(a.name))
        },
        toggleFilters() {
            this.showFilters = !this.showFilters;
        },
        hideFilters() {
            this.showFilters = false;
        }

    },

}

</script>

<style lang="less" scoped>

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 18;
    cursor: pointer;
    font-size: 18px;
}

.sp-modal-card-container {
    height: 100%;
    overflow: hidden;
    background-color: var(--color-bg-surface);
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    &:first-child{
       margin-left: 0;
   }
    &:last-child{
       margin-right: 0;
   }

    &-row {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 24px;
        cursor: pointer;

        &:hover{
            background-color: var(--color-box-shadow);
        }
        &.selected-row {
            font-weight: bold;
            background-color: var(--color-box-shadow);
        }
        .sp-severity-label {
            position: absolute;
            right: 10px;
        }
    }
}

.sp-modal-card-header {
    border-top: 3px solid var(--color-border-header);
    border-bottom: 1px solid var(--color-border-header);
    padding: 10px;
    position: relative;

    .expand-filters {
        position: absolute;
        right: 10px;
        top: 10px;
        span {
            &.opened-filters,
            &:hover{
                background-color: var(--color-box-shadow);
            }
        }
    }
    .filters-block{
        display: none;
        border-top: 3px solid var(--color-border-header);
        border-bottom: 1px solid var(--color-border-header);
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        padding: 10px;
        flex-wrap: wrap;
        align-items: center;
        background-color: var(--color-bg-surface);
        z-index: 100;

        &.visible{
            display: flex;
        }

        input{
            max-width: 100%;
            color: var(--color-fg-surface);
            background-color: var(--color-box-shadow);
            border: 1px solid var(--color-border-header);
            margin-right: 5px;
        }
        >div{
            padding: 5px 0 0 0 ;
        }
        span {
            &:hover{
                background-color: var(--color-box-shadow);
            }
        }


    }
}

.sp-modal-card-body {
    padding: 10px;
    height: calc(100% - 40px);
    width: 100%;
    overflow: hidden;
    position: relative;

    &:hover {
        overflow: auto;
    }
}


</style>
