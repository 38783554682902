import axios from 'axios'
import store from './store'


const axiosInstance = axios.create()

axiosInstance.defaults.headers.post['contentType'] = 'application/json'

axiosInstance.interceptors.response.use(
    function (response) { return response },
    function (error) {
        if ([401, 403].includes(error.response.status) && store.getters.isAuthenticated ) {
            store.dispatch('logout')
        }
        return Promise.reject(error)
    }
)

export { axiosInstance }
