import { createRouter, createWebHashHistory } from 'vue-router'

import CVEView from './components/CVEView.vue'
import StatisticsView from './components/StatisticsView.vue'

const routes = [
    { path: '/cve', component: CVEView },
    { path: '/statistics', component: StatisticsView },
    { path: '/', redirect: '/cve' },
]

export default createRouter({
    history: createWebHashHistory(),
    routes,
})
