<template>
<div class="sp-content-wrapper">
    <div class="sp-content-header">
        <h1>Statistics</h1>
    </div>
    <div class="sp-content">
<!--
        <div v-if="!isCustomer">
            <div v-for="(components, severity) in dev_stat_info" :key="severity">
                <div class="cvetable-row cvetable-row-header">
                    <div class="cvetable-col col-2">TOP {{ severity }} CVEs</div>
                </div>
                <div class="sp-section">
                    <div v-for="(stat, component) in components" :key="component">
                        <div v-if="Object.keys(stat).length > 0" class="sp-topcve-card-wrapper">
                            <div class="sp-product-card">
                                <div class="sp-product-header-wrapper">
                                    <div class="sp-product-header">{{ component }}</div>
                                    <div class="sp-product-header-col sp-topcve-severity-col">Severity</div>
                                    <div class="sp-product-header-col">Count</div>
                                    <div class="sp-product-header-col sp-topcve-header-comment-col">Comment</div>
                                </div>
                                <div v-for="item in stat" :key="item" class="sp-product-line-wrapper">
                                    <div class="sp-component-name">
                                        {{ item.name }}
                                    </div>
                                    <div class="sp-component-count sp-topcve-severity-col">
                                        {{ getSeverity(item) }}
                                    </div>
                                    <div class="sp-component-count">
                                        {{ item.count }}
                                    </div>
                                    <div class="sp-component-count  sp-topcve-comment-col">
                                        <div v-if="item.mira_comment != null">{{ item.mira_comment }}</div>
                                        <div v-if="item.mira_cvss3_vector != null">AV: {{ item.mira_cvss3_vector }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
        <div v-for="(products, section) in diff_stat_info" :key="section">
            <div class="cvetable-row cvetable-row-header">
                <div class="cvetable-col col-2">{{ section }} Diff</div>
            </div>
            <div class="sp-section">
                <div v-for="product in products" :key="product">
                    <div class="sp-product-card-wrapper">
                        <table class="sp-product-header-table">
                            <thead >
                                <tr >
                                    <th scope="col"> {{ product.name }}</th>
                                    <th scope="col">Critical</th>
                                    <th scope="col">High</th>
                                    <th scope="col">Clusters</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="component in product.components" :key="component">
                                    <td>{{ component.name }}_{{ component.version }}  → {{ component.name_master }}_{{ component.version_master }}</td>
                                    <td style="color:#972b1e">{{ component.severities[0] ? component.severities[0].count : 0 }}  → {{ component.severities[0] ? component.severities[0].count_diff : 0 }}</td>
                                    <td style="color:#ff5252">{{ component.severities[1] ? component.severities[1].count : 0 }}  → {{ component.severities[1] ? component.severities[1].count_diff : 0 }}</td>
                                    <td v-for="cluster in component.clusters" :key="cluster" class="sp-product-header-table-cluster">
                                        {{ cluster.version }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!--
        <div v-for="(products, section) in diff_stat_info" :key="section">
            <div class="cvetable-row cvetable-row-header">
                <div class="cvetable-col col-2">{{ section }}</div>
            </div>
            <div class="sp-section">
                <div v-for="product in products" :key="product">
                    <div class="sp-product-card-wrapper">
                        <div class="sp-product-card">
                            <div class="sp-product-header-wrapper">
                                <div class="sp-product-header">
                                    {{ product.name }}
                                </div>
                                <div class="sp-product-header-col">
                                    Critical
                                </div>
                                <div class="sp-product-header-col">
                                    High
                                </div>
                            </div>
                            <div v-for="component in product.components" :key="component">
                                <div class="sp-product-line-wrapper">
                                    <div class="sp-component-name">
                                        {{ component.name }}_{{ component.version }}
                                    </div>
                                    <div class="sp-component-count">
                                        {{ component.severities[0] ? component.severities[0].count : 0 }}
                                    </div>
                                    <div class="sp-component-count">
                                        {{ component.severities[1] ? component.severities[1].count : 0 }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div v-for="(products, section) in stat_list" :key="section">
            <div class="cvetable-row cvetable-row-header">
                <div class="cvetable-col col-2">{{ section }}</div>
            </div>
            <div class="sp-section">
                <div v-for="product in products" :key="product">
                    <div class="sp-product-card-wrapper">
                     <div class="sp-section">
                                <table class="sp-product-header-table">
                                    <thead >
                                        <tr >
                                            <th v-if="product.create_reason != ''" scope="col"><a :href="product.create_reason" > {{ product.name }} {{ product.version }} </a></th>
                                            <th v-else> {{ product.name }} {{ product.version }} </th>
                                            <th scope="col">Critical</th>
                                            <th scope="col">High</th>
                                            <th scope="col">Scan Date</th>
                                            <th scope="col">Clusters</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr v-for="component in product.components" :key="component">
                                            <td >{{ component.name }}_{{ component.version }}</td>
                                            <td style="color:#972b1e">{{ component.severities[0] ? component.severities[0].count : 0 }}</td>
                                            <td style="color:#ff5252">{{ component.severities[1] ? component.severities[1].count : 0 }}</td>
                                            <td>{{ component.date }}</td>
                                            <td v-for="cluster in component.clusters" :key="cluster" class="sp-product-header-table-cluster">
                                                {{ cluster.version }}
                                            </td>
                                        </tr>
                                        <tr style="font-weight:bold">
                                            <td>Total</td>
                                            <td>{{ totalCount(section, product.id, 1) }}</td>
                                            <td>{{ totalCount(section, product.id, 2) }}</td>
                                            <td/>
                                            <td/>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <!--
                        <div class="sp-product-card">
                            <div class="sp-product-header-wrapper">
                                <div class="sp-product-header">
                                    {{ product.name }} {{ product.version }}
                                </div>
                                <div class="sp-product-header-col">
                                    Critical
                                </div>
                                <div class="sp-product-header-col">
                                    High
                                </div>
                                <div class="sp-product-header-col">
                                    Scan Date &nbsp;
                                </div>
                                <div class="sp-product-header-col">
                                    Clusters &nbsp;
                                </div>
                            </div>
                            <div v-for="component in product.components" :key="component">
                                <div class="sp-product-line-wrapper">
                                    <div class="sp-component-name">
                                        {{ component.name }}_{{ component.version }}
                                    </div>
                                    <div class="sp-component-count">
                                        {{ component.severities[0] ? component.severities[0].count : 0 }}
                                    </div>
                                    <div class="sp-component-count">
                                        {{ component.severities[1] ? component.severities[1].count : 0 }}
                                    </div>
                                    <div class="sp-component-date">
                                        {{ component.date }} &nbsp;
                                    </div>
                                    <div v-for="cluster in component.clusters" :key="cluster" class="sp-component-date">
                                            {{ cluster.version }}&nbsp;
                                    </div>
                                </div>
                            </div>
                            <div class="sp-product-footer-wrapper">
                                <div class="sp-product-footer">Total</div>
                                <div class="sp-product-footer-col">{{ totalCount(section, product.id, 1) }}</div>
                                <div class="sp-product-footer-col">{{ totalCount(section, product.id, 2) }}</div>
                                <div class="sp-product-footer-date"/>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="cvetable-row cvetable-row-header">
            <div class="cvetable-col col-2">TOP Vulnerable artifacts</div>
        </div>
        <div class="sp-section">
            <div v-for="(artifacts, product) in dev_art_stat_info" :key="product">
                <div class="sp-topcve-card-wrapper">
                    <div class="sp-product-card">
                        <div class="sp-product-header-wrapper">
                            <div class="sp-product-header">{{ product }}</div>
                            <div class="sp-product-header-col sp-topcve-total-col">CVEs</div>
                        </div>
                        <div v-for="artifact in artifacts" :key="artifact" class="sp-product-line-wrapper">
                            <div class="sp-component-name">
                                {{ artifact.name }}
                            </div>
                            <div class="sp-component-count">
                                {{ artifact.total }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cvetable-row cvetable-row-header">
            <div class="cvetable-col col-2">TOP Vulnerabilities</div>
        </div>
        <div class="sp-section">
            <div v-for="(cves, product) in dev_cve_stat_info" :key="product">
                <div class="sp-topcve-card-wrapper">
                    <div class="sp-product-card">
                        <div class="sp-product-header-wrapper">
                            <div class="sp-product-header">{{ product }}</div>
                            <div class="sp-product-header-col sp-topcve-total-col">Total</div>
                        </div>
                        <div v-for="cve in cves" :key="cve" class="sp-product-line-wrapper">
                            <div class="sp-component-name">
                                {{ cve.name }}
                            </div>
                            <div class="sp-component-count">
                                {{ cve.total }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</div>
</template>

<style scoped>
a:link {
    color: var(--color-fg);
    background-color: transparent;
    text-decoration: underline;
}
a:hover {
    background-color: transparent;
    text-decoration: underline;
}
.sp-section {
    display: flex;
    flex-wrap: wrap;
}
a:visited {
    color: var(--color-fg);
    background-color: transparent;
    text-decoration: none;
}
.sp-product-card-wrapper, .sp-topcve-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-left: 13px;

}
.sp-topcve-card-wrapper {
    max-width: 900px;
}
.sp-product-card {
    background-color: var(--color-bg-surface);
    margin-bottom: 10px;

}
.sp-product-header-table {
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: left;
    background-color: var(--color-bg-surface);
    border-top: 1px solid var(--color-border-header);
    border-bottom: 0.1px solid var(--color-border-header);
    margin-bottom: 10px;
}
.sp-product-header-table th {
    padding: 3px;
    text-align: left;
    border-bottom: 0.1px solid var(--color-border-header);
}
.sp-product-header-table td {
    padding: 2px;
    text-align: left;
    border-bottom: 0.1px solid var(--color-border-header);
}

.sp-product-header-table tr:hover {
    background-color: var(--color-bg-header);
}
.sp-product-header-table tbody tr:last-child td {
    border-bottom: 0;
}
.sp-product-header-table tbody tr:last-child th {
    border-bottom: 0;
}
.sp-product-header-table-cluster {
    background-color: var(--color-border-cluster);
    border-radius: 5%;
    opacity: 0.4;
}

.sp-product-header-wrappr-table, .sp-product-footer-wrapper-table {
    background-color: var(--color-bg-header);
    border-top: 1px solid var(--color-border-header);
    border-bottom: 1px solid var(--color-border-header);
    line-height: 20px;
    font-weight: 600;
}
.sp-product-header-wrapper, .sp-product-line-wrapper, .sp-product-footer-wrapper {
    display: flex;
    padding-left: 13px;
    min-height: 28px;
    align-items: center;
    justify-content: space-between;
}
.sp-product-line-wrapper:hover {
    background-color: var(--color-border-header);
    cursor: pointer;
}
.sp-product-header-wrapper, .sp-product-footer-wrapper {
    background-color: var(--color-bg-header);
    border-top: 1px solid var(--color-border-header);
    border-bottom: 1px solid var(--color-border-header);
    line-height: 20px;
    font-weight: 600;
}
.sp-product-header, .sp-component-name, .sp-product-footer {
    min-width: 200px;
}
sp-product-header a{
   color: inherit;
}
.sp-product-header-col, .sp-component-count, .sp-product-footer-col {
    min-width: 50px;
    text-align: center;
}
.sp-component-count-diff{
    min-width: 75px;
    text-align: center;
}
.sp-product-header-diff, .sp-component-name-diff {
    min-width: 350px;
    text-align: start;
}
.sp-product-footer-date {
    min-width: 90px;
    text-align: center;
}
.sp-component-date {
    min-width: 90px;
    text-align: center;
}
.sp-topcve-severity-col {
    min-width: 120px;
}
.sp-topcve-header-comment-col {
    width: 100%;
}
.sp-topcve-comment-col {
    text-align: start;
    margin: 5px 15px 5px 15px;
}
.sp-topcve-total-col {
    margin: 5px 0 5px 15px;
}
.sp-content-header {
    padding: 15px 15px 0 15px;
}
.sp-content-header>h1 {
    margin: 0;
    font-size: 24px;
    margin-bottom: 15px;
}
.cvetable-row {
    line-height: 50px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 5px;
}
.cvetable-row-header {
    display: flex;
    justify-content: flex-start;
    background-color: var(--color-bg-header);
    border-top: 2px solid var(--color-border-header);
    border-bottom: 1px solid var(--color-border-header);
    line-height: 40px;
    font-weight: 600;
    padding-left: 13px;
}
.col-2 {
    width: 16%;
}
</style>

<script>
    export default {
        name: 'StatisticsView',
        data() { return {
            stat_list: {},
            dev_art_stat_info: {},
            diff_stat_info:{},
        }},
        components: {
        },
        methods: {
            totalCount(product_name, product_id, severity_id) {
                const total = this.$data.stat_list[product_name]
                .filter(it => it.id == product_id)[0].total[severity_id]
                return total
            },
            getSeverity(item) {
                let result = item.severity.name
                if (item.severity.id != item.mira_severity.id) {
                    result += ' - > ' + item.mira_severity.name
                }
                return result
            },
        },
        watch: {
        },
        computed: {
            isCustomer() { return this.$store.state.userProfile.customer },
        },
        async created() {
            const app = this

            await app.$axios.get('/api/statinfo/')
            .then(response => { app.stat_list = response.data })
            .catch(error => console.log(error))

            await app.$axios.get('/api/devstatinfo/artifact/?count=5')
            .then(response => { app.dev_art_stat_info = response.data })
            .catch(error => console.log(error))

            await app.$axios.get('/api/devstatinfo/cve/?count=5')
            .then(response => { app.dev_cve_stat_info = response.data })
            .catch(error => console.log(error))


            if (!app.$store.state.userProfile.customer) {
                await app.$axios.get('/api/devstatinfo/')
                .then(response => { app.dev_stat_info = response.data })
                .catch(error => console.log(error))

                await app.$axios.get('/api/devreldiff/')
                .then(response => { app.diff_stat_info = response.data })
                .catch(error => console.log(error))

            }
        },
    }
</script>

