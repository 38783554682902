<template>
<div class="sp-header">
    <div class="sp-header__logo">
        <img src="@/assets/images/mirantis-logo-white.svg">
        <div class="sp-header__separator"/>
        <span>Security Dashboard</span>
    </div>
    <div class="sp-header__controls">
        <ThemeSwitcher/>
        <div class="sp-profile">
            <span class="sp-profile-text">{{ iconText }}</span>
        </div>
        <span class="sp-logout material-symbols-outlined" @click="logout">Logout</span>
    </div>
</div>
</template>

<script>
import ThemeSwitcher from './ThemeSwitcher.vue'

export default {
    name: 'HeadBar',
    components: {
        ThemeSwitcher,
    },
    computed: {
        iconText() { return this.$store.state.userProfile.icon_text },
    },
    methods: {
        logout() { this.$store.dispatch('logout') },
    }
}
</script>

<style scoped>
.sp-header__controls {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
.sp-profile {
    height: 35px;
    width: 35px;
    background-color: #ab46bc;
    border-radius: 50%;
    margin: 10px;
    user-select: none;
    background-repeat: round;
}
.sp-profile-text {
    height: inherit;
    width: inherit;
    display: table-cell;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    text-transform: uppercase;
    color: #fff;
}
.sp-logout {
    font-size: 20px;
    color: #fff;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}
.sp-header {
    background-color: #4ba0f3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgb(64 77 89 / 75%);
    padding-left: 60px;
    z-index: 100;
    box-sizing: border-box;
    -webkit-font-smoothing: auto;
}
.sp-header__logo {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 400;
    font-family: Open Sans,Roboto,sans-serif;
    letter-spacing: 0;
    line-height: unset;
    font-style: normal;
    text-decoration: none;
    color: #fff;
}
.sp-header__separator {
    width: 1px;
    height: 28px;
    background-color: #fff;
    opacity: .6;
    margin-left: 8px;
    margin-right: 8px;
}
</style>
